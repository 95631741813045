$white: #fff;
$black: #000;
$black-opacity: rgba($black, 0.7);
$blue: #3052ff;
$blue-opacity: rgba($blue, 0.7);
$orange: #f8bf4c;
$green: #3bdc96;
$red: #ff5b5b;
$grey: #606885;
$silver: #acb1c1;
$light: #f8fafd;
$light-blue: #f3f5ff;
$light-orange: #fffbf4;
$light-green: #f3fdf9;
$light-red: #fff5f5;
$moonlight: #181a21;
$royal: #030b36;
